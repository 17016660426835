<template>
  <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.4447 9.13867H12.0915C12.063 9.13088 12.037 9.1179 12.0084 9.11271C11.2657 8.99844 10.6788 8.38037 10.6295 7.65583C10.5723 6.84558 11.0346 6.15999 11.7877 5.93665C11.8864 5.90809 11.9902 5.88731 12.0915 5.86134H12.4447C12.4681 5.86913 12.4888 5.88212 12.5148 5.88471C13.2004 5.99638 13.7224 6.4898 13.8704 7.16501C13.8808 7.21695 13.8964 7.26889 13.9094 7.32082V7.67401C13.9016 7.69738 13.8886 7.72076 13.886 7.74413C13.7769 8.41933 13.2965 8.93352 12.6291 9.08935C12.5641 9.10493 12.5044 9.12311 12.4447 9.13869L12.4447 9.13867ZM2.46207 5.86133L2.81528 5.86134C2.84385 5.86913 2.86981 5.88212 2.89838 5.88471C3.6463 6.00158 4.23062 6.62225 4.27736 7.35459C4.3293 8.16224 3.86185 8.84523 3.10614 9.06597C3.01004 9.09454 2.91137 9.11531 2.81527 9.13869H2.46208C2.43871 9.1309 2.41793 9.11791 2.39456 9.11272C1.71935 8.98806 1.27787 8.59593 1.06752 7.94149C1.03895 7.8558 1.02337 7.7649 1 7.67661L1 7.32342C1.00779 7.30005 1.02078 7.27668 1.02337 7.2533C1.13504 6.5755 1.61288 6.0639 2.28029 5.90809C2.34262 5.89249 2.40235 5.87691 2.46207 5.86133ZM7.27682 5.86134H7.63001C7.65857 5.86913 7.68454 5.88212 7.71311 5.88731C8.45843 6.00417 9.05833 6.64822 9.08949 7.37536C9.12585 8.1934 8.63243 8.88678 7.87152 9.08156C7.79102 9.10233 7.71051 9.12051 7.63001 9.13869H7.27682C7.24825 9.1309 7.22228 9.11791 7.19372 9.11272C6.44839 8.99845 5.84849 8.35181 5.81474 7.62467C5.77579 6.80663 6.2718 6.11324 7.03271 5.91847C7.11581 5.8977 7.19632 5.87952 7.27682 5.86134Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'IconDotsH'
}
</script>
